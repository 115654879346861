<template>
  <div>
    <div
      class="pt-4 pb-4"
      style="font-size:20px; font-weight:bold; padding-left: 12px;"
    >
      <span class="pl-2">{{ $t("about.faq") }}</span>
    </div>
    <el-container style="height: auto; border: 1px solid #eee; " class="pt-2">
      <el-aside
        width="240px"
        style="background-color: fff; height: auto;"
        class="d-none d-sm-block"
      >
        <el-menu
          :default-openeds="['0', '1']"
          :default-active="id"
          style="font-weight: 500; overflow-y: auto;"
          text-color="#42a16a"
          active-text-color="#757575"
        >
          <el-submenu
            v-for="(label, index) in getFaqs.faq_labels"
            :key="index"
            :index="index.toString()"
          >
            <template slot="title">
              <div style="font-size:16px; font-weight:bold;">
                {{ helper.displayI18nText($i18n.locale, label.label) }}
              </div>
            </template>
            <el-menu-item
              v-for="(topic, index) in label.topics"
              :key="index"
              :index="handleRouterName(topic.title.en)"
              @click="handleSelect(topic, label)"
              >{{ helper.displayI18nText($i18n.locale, topic.title) }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </el-aside>

      <!-- 手機模式 -->
      <el-container>
        <el-main>
          <div class="m-3 pt-2 d-md-none">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ name: 'LandingPage' }"
                >Home</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ name: 'About' }"
                >About</el-breadcrumb-item
              >
              <el-breadcrumb-item>
                {{ helper.displayI18nText($i18n.locale, breadLabel) }} -
                {{ helper.displayI18nText($i18n.locale, breadTopic) }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="d-md-none main">
            <h1 class="title pb-3">{{ $t("about.faq") }}</h1>
            <el-select
              v-model="selectedTopicId"
              @change="handleSelectDropdown(selectedTopicId)"
              placeholder="Please select"
              style="width:100%"
            >
              <el-option-group
                v-for="(label, index) in getFaqs.faq_labels"
                :key="index"
                :label="helper.displayI18nText($i18n.locale, label.label)"
              >
                <el-option
                  v-for="(topic, index) in label.topics"
                  :key="index"
                  :label="helper.displayI18nText($i18n.locale, topic.title)"
                  :value="handleRouterName(topic.title.en)"
                ></el-option>
              </el-option-group>
            </el-select>
            <br />
          </div>

          <div class="main">
            <router-view />
          </div>

          <div class="footer">
            <span>
              {{ $t("about.anyQuestion") }}
              <router-link
                :to="{ name: 'LandingPageContact', params: { id: 'contact' } }"
                style="color: #42a16a"
                >{{ $t("about.contactUs") }}</router-link
              >
            </span>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  // 此唯一 props "id" 是 router 變數，小寫 & 空白改為 hyphen
  // label_id = topic.faq_label_id
  props: ["id"],

  data() {
    return {
      // 此項變數用在 select 選擇器
      selectedTopicId: this.id,
      breadLabel: {},
      breadTopic: {},
      label_id: ""
    };
  },
  computed: {
    ...mapGetters("faq", ["getFaqs", "getCurrentLabel", "getCurrentTopic"]),
    displayTopic() {
      return this.helper.displayI18nText(this.$i18n.locale, this.currentTopicTitle);
    },
    helper() {
      return helper;
    }
  },
  watch: {},
  async created() {
    await this.$store.dispatch("faq/getFaqs");
    // 因為在 CollapseItems.vue 裡面已經將資料存進 Vuex，這邊直接讀取就好
    this.breadLabel = this.getCurrentLabel.label;
    this.breadTopic = this.getCurrentTopic.title;
  },
  mounted() {},
  methods: {
    // 把 router 的 params id 變成小寫 + hyphen
    handleRouterName(str) {
      return str.replace(/\s+/g, "-").toLowerCase();
    },
    //
    async handleSelect(topic, label) {
      if (this.id !== this.handleRouterName(topic.title.en)) {
        await this.$store.dispatch("faq/saveCurrentLabel", label);
        await this.$store.dispatch("faq/saveCurrentTopic", topic);
        this.$router.push({
          name: "FaqChild",
          params: {
            id: this.handleRouterName(topic.title.en),
            faqs: topic.faqs
          }
        });
      }
    },
    // select 選擇器因為 v-model 只能是 string(topicId)，因此還要多一個步驟找出此項 id 的 topic
    handleSelectDropdown(topicId) {
      let currentTopic = {};
      let currentLabel = {};
      this.getFaqs.faq_labels.forEach(x => {
        x.topics.forEach(y => {
          if (this.handleRouterName(y.title.en) === topicId) {
            currentTopic = y;
          }
        });
      });
      this.getFaqs.faq_labels.forEach(x => {
        if (x.id === currentTopic.faq_label_id) {
          currentLabel = x;
        }
      });

      this.handleSelect(currentTopic, currentLabel);
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 30px;
}
.main {
  width: 80%;
  margin: 0px auto;
  text-align: center;
}
.footer {
  width: 80%;
  text-align: left;
  font-size: 14px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 30px;
}
.contactUs {
  color: #42a16a;
}
main.el-main {
  min-height: auto;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .bread {
    display: block;
  }
}
</style>
